// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import 'bootstrap';
require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');
require('custom/menu-aim');
require('custom/pace.min');
require('custom/owl.carousel.min');
require('custom/jquery.loadingModal.min');
require('custom/flash');
require('custom/script');

document.addEventListener('turbolinks:load', () => {
  $('[data-toggle="tooltip"]').tooltip();
});

global.Toastify = require('toastify-js');
