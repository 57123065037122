document.addEventListener('turbolinks:load', function () {
  if($('.flash').length > 0) {
    $('.flash').each(function() {
      Toastify({
        text: $(this).val(),
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'center',
      }).showToast();
    });
  }
});