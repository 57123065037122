document.addEventListener('turbolinks:load', function () {
  var shoppingCart = (function () {
    // =============================
    // Private methods and propeties
    // =============================
    cart = [];
    // Constructor
    function Item(
      otid,
      vid,
      url,
      shop,
      version,
      name,
      thumb,
      price,
      quantity,
      comment,
      discount,
    ) {
      this.otid = otid;
      this.vid = vid;
      this.url = url;
      this.shop = shop;
      this.version = version;
      this.name = name;
      this.thumb = thumb;
      this.price = price;
      this.quantity = quantity;
      this.comment = comment;
      this.discount = discount;
    }
    // Save cart
    function saveCart() {
      Date.prototype.addHours = function (h) {
        this.setTime(this.getTime() + h * 60 * 60 * 1000);
        return this;
      };

      const cartWithExpiry = {
        value: cart,
        expiry: new Date().addHours(36),
      };
      localStorage.setItem('cartInfo2', JSON.stringify(cartWithExpiry));
    }

    function isExpired(expiryTime) {
      var now = new Date().getTime();

      return now > expiryTime;
    }

    // Load cart
    function loadCart() {
      var cartInfo = localStorage.getItem('cartInfo2');
      var tempCart = JSON.parse(cartInfo);
      var expiryTime = new Date(tempCart.expiry).getTime();

      if (isExpired(expiryTime)) {
        localStorage.removeItem('cartInfo2');
      } else {
        cart = tempCart.value;
      }
    }

    if (localStorage.getItem('cartInfo2') != null) {
      loadCart();
    }
    // =============================
    // Public methods and propeties
    // =============================
    var obj = {};
    // Add to cart
    obj.add = function (
      otid,
      vid,
      url,
      shop,
      version,
      name,
      thumb,
      price,
      quantity,
      comment,
      discount,
    ) {
      for (var item in cart) {
        if (cart[item].otid === otid.toString() && cart[item].vid === vid.toString()) {
          cart[item].quantity = cart[item].quantity + quantity;
          cart[item].price = price;
          saveCart();
          return;
        }
      }
      var item = new Item(
        otid,
        vid,
        url,
        shop,
        version,
        name,
        thumb,
        price,
        quantity,
        comment,
        discount,
      );
      cart.push(item);
      saveCart();
    };

    obj.getCartInfo = function () {
      var cartInfo = localStorage.getItem('cartInfo2');
      var tempCart = JSON.parse(cartInfo);
      var expiryTime = new Date(tempCart.expiry).getTime();

      if (isExpired(expiryTime)) {
        localStorage.removeItem('cartInfo2');
      } else {
        cart = tempCart.value;
      }

      return cart;
    };

    obj.setQuantity = function (id, version, count) {
      for (var i in cart) {
        if (cart[i].otid === id.toString() && cart[i].vid === version.toString()) {
          cart[i].quantity = count;
          break;
        }
      }
      saveCart();
    };

    obj.setComment = function (id, version, comment) {
      for (var i in cart) {
        if (cart[i].otid === id.toString() && cart[i].vid === version.toString()) {
          cart[i].comment = comment;
          break;
        }
      }
      saveCart();
    };

    obj.remove = function (id, version) {
      for (var item in cart) {
        if (cart[item].otid === id.toString() && cart[item].vid === version.toString()) {
          cart.splice(item, 1);
          break;
        }
      }
      saveCart();
    };

    obj.totalCount = function () {
      var totalCount = 0;
      for (var item in cart) {
        totalCount += cart[item].quantity;
      }
      return totalCount;
    };

    obj.listCart = function () {
      return cart;
    };

    return obj;
  })();

  if($('#cats').length > 0) {
    $('#cats').owlCarousel({
      margin: 0,
      nav: true,
      dots: false,
      loop: false,
      items: 10,
      autoplay: false,
      responsive: {
        0: {
          items: 3,
        },
        768: {
          items: 6,
        },
        1024: {
          items: 10,
        },
      },
    });
  }

  if($('#thumbnails').length > 0)
  {
    $('#thumbnails').owlCarousel({
      nav: true,
      margin: 0,
      autoWidth:true,
      dots: false,
    });
  }

  if($('#collection').length > 0)
  {
    $('#collection').owlCarousel({
      margin: 30,
      nav: true,
      dots: false,
      loop: true,
      items: 6,
      autoplay: true,
      autoplayTimeout: 3000,
      autoplayHoverPause: true,
      responsive: {
        0: {
          items: 1,
        },
        480: {
          items: 2,
        },
        768: {
          items: 4,
        },
        1024: {
          items: 6,
        },
      },
    });
  }

  if($('#products').length > 0)
  {
    $('#products').owlCarousel({
      margin: 10,
      nav: true,
      dots: false,
      loop: true,
      items: 5,
      autoplay: false,
      responsive: {
        0: {
          items: 1,
        },
        480: {
          items: 2,
        },
        768: {
          items: 4,
        },
        1024: {
          items: 5,
        },
      },
    });
  }

  //shop list
  var toggled = false;
  $('#top-vendors, #close-vendors, #top-vendors2').on('click', function (e) {
    $('#sidebar').fadeToggle();
    if (toggled === false) {
      toggled = true;
      $.get('/vendors', function (data, status) {
        $('#vendors').html(data);
      });
    }
  });

  var p = 'Taobao';
  $("#provider").on('change', function (e) {
    p = $(this).val();
  });
  
  $('.card-product-grid').on('click', function() {
    
    $('body').loadingModal({ text: '' });
  });

  //auto complete
  $('#query').on('input', function () {
    var val = $(this).val();
    if (val.length > 2) {
      $.get('/suggest?query=' + val + '&provider=' + p, function (data, status) {
        if (data != '') {
          $('#autocomplete').show();
          $('#autocomplete').html(data);
        } else {
          $('#autocomplete').hide();
          $('#autocomplete').html('');
        }
      });
    } else {
      $('#autocomplete').hide();
      $('#autocomplete').html('');
    }
  });

  $('#autocomplete').hide();

  if ($('[data-toggle="tooltip"]').length > 0) {
    $('[data-toggle="tooltip"]').tooltip();
  }

  $('#filter-form .custom-control-input').on('change', function (e) {
    $('#filter-form').trigger('submit');
  });

  $('#sort-form select').on('change', function (e) {
    $('#sort-form').trigger('submit');
  });

  $('#button-photo').on('click', function (e) {
    $('#file-photo').trigger('click');
  });

  $('#file-photo').on('change', function (e) {
    $('#form-photo').trigger('submit');
  });

  //item
  $('#button-wish').on('click', function (e) {
    var id = $('#cart-otid').val();
    var image = $('#cart-thumb').val();
    var title = $('#cart-name').val();
    var price = parseInt($('#cart-price').val().replace(/,/g, ''));
    $.ajax({
      type: 'GET',
      url:
        '/items/wish?id=' +
        id +
        '&image=' +
        encodeURIComponent(image) +
        '&title=' +
        encodeURIComponent(title) +
        '&price=' +
        price,
      success: function (data) {
        if (data) {
          $('#button-wish').removeClass('btn-primary');
          $('#button-wish').addClass('btn-light');
          Toastify({
            text: title + ' хасагдсан',
            duration: 3000,
            newWindow: true,
            close: true,
            gravity: 'top',
            position: 'center',
          }).showToast();
        } else {
          $('#button-wish').removeClass('btn-light');
          $('#button-wish').addClass('btn-primary');
          Toastify({
            text: title + ' таалагдсан',
            duration: 3000,
            newWindow: true,
            close: true,
            gravity: 'top',
            position: 'center',
          }).showToast();
        }
      },
    });
  });

  $('#button-cart').on('click', function (e) {
    if (shoppingCart.totalCount() > 100) {
      Toastify({
        text: 'Нэг удаагийн захиалгаар 100-с дээш бараа захиалах боломжгүй',
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'center',
      }).showToast();
    }
    else if ($('#item-quantity').text() === '0') {
      Toastify({
        text: 'Барааны үлдэгдэл дууссан байна',
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'center',
      }).showToast();
    } else {
      var otid = $('#cart-otid').val();
      var vid = $('#cart-vid').val();
      var url = $('#cart-url').val();
      var shop = $('#cart-shop').val();
      var version = $('#cart-version').val();
      var name = $('#cart-name').val();
      var thumb = $('#cart-thumb').val();
      var price = parseInt($('#item-price').html().replace(/,/g, ''));
      var quantity = parseInt($('#item-cart-count').val().replace(/,/g, ''));
      var discount = $('#cart-discount').val() === "1";

      shoppingCart.add(
        otid,
        vid,
        url,
        shop,
        version,
        name,
        thumb,
        price,
        quantity,
        '',
        discount,
      );

      loadCart();

      $('#item-cart').addClass('shake');
      setTimeout(function () {
        $('#item-cart').removeClass('shake');
      }, 500);

      Toastify({
        text: 'Сагсанд нэмэгдсэн',
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'center',
      }).showToast();
    }
  });

  $('#item .thumbs-wrap a').on('click', function (e) {
    $('#item .thumbs-wrap a').removeClass('active');
    $(this).addClass('active');
    $('#item video').trigger('pause');
    var configtype = $(this).attr('config-type');
    if (configtype == '0') {
      $('#item-image').removeClass('d-block');
      $('#item-image').addClass('d-none');
      $('#item video').removeClass('d-none');
      $('#item video').addClass('d-block');
      var configid = $(this).attr('config-id');
      $('#item-video-' + configid).trigger('play');
    } else if (configtype == '1') {
      $('#item-image').removeClass('d-none');
      $('#item-image').addClass('d-block');
      $('#item video').removeClass('d-block');
      $('#item video').addClass('d-none');
      var configimage = $(this).attr('config-image');
      $('#item-image').attr('src', configimage);
    }
  });

  $('#button-plus').on('click', function (e) {
    var q = parseInt($('#item-cart-count').val());
    q = q + 1;
    $('#item-cart-count').val(q);
  });

  $('#button-minus').on('click', function (e) {
    var q = parseInt($('#item-cart-count').val());
    if (q > 1) {
      q = q - 1;
    }
    $('#item-cart-count').val(q);
  });

  function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
      sURLVariables = sPageURL.split('&'),
      sParameterName,
      i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');

      if (sParameterName[0] === sParam) {
        return typeof sParameterName[1] === undefined
          ? true
          : decodeURIComponent(sParameterName[1]);
      }
    }
    return false;
  }

  //empty cart
  function emptyCart() {
    var tableBody = `
    <div class="card p-4">
      <span class="text-center">Сагс хоосон байна.</span>
    </div>
  `;
  
    total = 0;

    $('#cart-table-body').html(tableBody);
    $('.cart-total-price').text(numberWithDelimitr(total));
    $('#cart-order-btn').addClass('disabled');
  }

  function numberWithDelimitr(number) {
    return Number(number.toFixed(1)).toLocaleString();
  }

  function itemremove(id, version) {
    shoppingCart.remove(id, version);
    loadCart();
  }
  
  function itemchange(id, version, counter) {
    shoppingCart.setQuantity(id, version, counter);
    loadCart();
  }

   //load cart
  function loadCart() {
    var totalCount = shoppingCart.totalCount();
    if (totalCount > 0) {
      $('.cart-warning').show();
      $('#item-cart, #item-cart2').show();
      $('#item-cart, #item-cart2').html(totalCount);
    } else {
      $('.cart-warning').hide();
      $('#item-cart, #item-cart2').hide();
    }
    if (localStorage.getItem('cartInfo2') != null) {
      var cartInfo = shoppingCart.getCartInfo();

      if (cartInfo.length === 0) {
        emptyCart();
      } else {
        var tableBody1 = [];

        var total = 0;

        function calculateTotalPrice(price, count) {
          total = total + price * count;
        }

        var gap = false;
        var gal = false;
        cartInfo.forEach((item) => {
          calculateTotalPrice(item.price, item.quantity);
          if(item.otid.indexOf('GAP000') > -1) {
            gap = true;
          }
          var link = `https://www.tshop.mn/items/${item.otid}?skuId=${item.vid}`;
          if(item.otid.indexOf('GAL') > -1) {
            link = item.url;
            gal = true;
          }
          tableBody1.push(`
          <div class="card mb-2">
            <div class="container py-3">
              <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 pb-lg-0 pb-xl-0 pb-sm-2 pb-2">
                  <figure class="itemside">
                    <div class="aside">
                      <img src="${item.thumb}" class="rounded img-sm">
                    </div>
                    <figcaption class="info">
                      <div class="row">
                        <div class="col-9 col-md-10 col-sm-10 col-lg-12 col-xl-12">
                          
                          <a href="${link}" 
                            class="title text-dark">
                            ${item.name.substring(0, 40)}...
                          </a>
                        </div>
          
                        <div class="col-3 col-md-2 col-sm-2 d-lg-none d-xl-none text-right">
                          <button class="btn btn-light btn-sm cart-product-remove"
                            data-id="${item.otid}" data-version="${item.vid}">
                            <i class="las la-times-circle"></i>
                          </button>
                        </div>
                      </div>
          
                      <div class="py-1">
                        <small><b>${item.version}</b></small>
                      </div>
          
                      <div class="row d-lg-none d-xl-none">
                        <div class="col-6 col-sm-6">
                          <div class="btn-group">
                            <button type="button" class="btn btn-primary btn-sm decrement-counter" 
                              data-version="${item.vid}">-</button>
                            <input type="text" readonly value="${item.quantity}"
                              class="form-control counter-input 
                              counter-input-${item.vid}"
                              data-id="${item.otid}"
                              data-version="${item.vid}" />
                            <button type="button" class="btn btn-primary btn-sm increment-counter" 
                              data-version="${item.vid}">+</button>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 text-right">
                          <div class="price-wrap">
                            <var class="price h5">
                              ${numberWithDelimitr(item.price)}₮
                            </var>
                          </div>
                        </div>
                      </div>
                    </figcaption>
                  </figure>
                </div>

                <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                  <textarea value="${item.comment}" rows="3" 
                    class="form-control product-comment-placeholder"
                    data-version="${item.vid}"
                    data-id="${item.otid}"
                    placeholder="Тус бүтээгдэхүүнтэй холбоотой санамж байвал бичнэ үү ...">${item.comment}</textarea>
                </div>
          
                <div class="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-4 py-lg-0 py-xl-0 py-sm-2 py-2 d-none d-lg-block text-right">
                  <div class="btn-group">
                    <button type="button" class="btn btn-primary decrement-counter" 
                      data-version="${item.vid}">-</button>
                    <input type="text" readonly value="${item.quantity}"
                      class="form-control counter-input 
                      counter-input-${item.vid}"
                      data-id="${item.otid}" data-version="${item.vid}" />
                    <button type="button" class="btn btn-primary increment-counter" 
                      data-version="${item.vid}">+</button>
                  </div>
                </div>
          
                <div class="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-4 py-lg-0 py-xl-0 py-sm-2 py-2 d-none d-lg-block text-right">
                  <div class="price-wrap">
                    <var class="price h5">
                      ${numberWithDelimitr(item.price)}₮
                    </var>
                  </div>
                </div>
          
                <div class="col-xl-1 col-lg-1 col-md-4 col-sm-4 col-4 py-lg-0 py-xl-0 py-sm-2 py-2 d-none d-lg-block text-right">
                  <button class="btn btn-light cart-product-remove"
                    data-id="${item.otid}" data-version="${item.vid}">
                    <i class="las la-times-circle"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        `);
        });

        if(gap) {
          $('.cart-gap').show();
          $('.cart-gan').hide();
        }
        else {
          $('.cart-gap').hide();
          $('.cart-gan').show();
        }

        $('#cart-table-body').html(tableBody1);

        $('.increment-counter').on('click', function () {
          var inputVal = $('.counter-input-' + $(this).data('version')).val();

          itemchange(
            `${$('.counter-input-' + $(this).data('version')).data('id')}`,
            `${$('.counter-input-' + $(this).data('version')).data('version')}`, parseInt(inputVal) + 1);
        });

        $('.decrement-counter').on('click', function () {
          var inputVal = $('.counter-input-' + $(this).data('version')).val();

          if (parseInt(inputVal) > 1) {
            itemchange(
              `${$('.counter-input-' + $(this).data('version')).data('id')}`,
              `${$('.counter-input-' + $(this).data('version')).data('version')}`, parseInt(inputVal) - 1);
          }
        });

        $('.cart-product-remove').on('click', function () {
          itemremove(`${$(this).data('id')}`, `${$(this).data('version')}`);
        });

        $('.cart-total-price').text(numberWithDelimitr(total));

        $('#cart-items-local').val(JSON.stringify(cartInfo));
      }
    } else {
      emptyCart();
    }
  }

  loadCart();

  //onload
  function initItem()
  {
    var sku = getUrlParameter('skuId');
    if($("#item-ot").length > 0) {
      var configst = $('#configs').val();
      var configs = '';

      if(configst) {
        configs = JSON.parse(configst);
      }

      if (sku) {
        var rightConfigs = configs.find((c) => c.id === sku);
        if (rightConfigs) {
          rightConfigs.vals.forEach((item) => {
            $('input.config-check').each(function () {
              var pid = $(this).attr('config-pid');
              var vid = $(this).attr('config-vid');

              if (pid === item.pid && vid === item.vid) {
                $(this).prop('checked', true);
              }
            });
          });
        }
      } else {
        $('input.init-check').each(function () {
          $(this).prop('checked', true);
        });
      }
    } else {
      if(sku) {
        $('#item dl input').each(function (t, o) {
          var vid = $(o).attr('config-vid');
          if (vid === sku) {
            $(this).prop('checked', true);
          }
        });
      } else {
        $('input.init-check').each(function () {
          $(this).prop('checked', true);
        });
      }
    }
    checkItem();
  }

  function checkItem() {
    if($("#item-ot").length > 0) {
      var configst = $('#configs').val();
      var configs = '';
      if(configst) {
        configs = JSON.parse(configst);
      }

      var keyst = $('#keys').val();
      var keys = '';
      if(keyst) {
        keys = JSON.parse(keyst);
      }

      for(var i=0;i<configs.length;i++) {
        var c = 0;
        var v = '';
        for(var j=0;j<configs[i].vals.length;j++) {
          $('#item dl input:checked').each(function (t, o) {
            var pid = $(o).attr('config-pid');
            var vid = $(o).attr('config-vid');
            var val = $(o).attr('config-value');
            var key = $(o).attr('config-key');
            if(pid.toString() === configs[i].vals[j].pid.toString() && vid.toString() === configs[i].vals[j].vid.toString()) {
              c++;
              v = v + key + ': ' + val;
            }
          });
        }
        if(keys.length === c) {
          $('#cart-vid').val(configs[i].id);
          $('#cart-version').val(v);
          $('#cart-price').val(configs[i].price);
          $('#cart-discount').val(configs[i].discount_price);
          $('#item-quantity').html(configs[i].quantity);
          var price = configs[i].price;
          var discount = configs[i].discount_price;
          if (parseInt(price.replace(/,/g, '')) > parseInt(discount.replace(/,/g, '')) && parseInt(discount.replace(/,/g, '')) > 0) {
            var t = price;
            price = discount;
            discount = t;
            $('#item-discount-container').show();
            $('#item-discount').html(discount);
            $('#cart-discount').val('1');
          } else {
            $('#item-discount-container').hide();
            $('#item-discount').html('');
            $('#cart-discount').val('0');
          }
          $('#item-price').html(price);
          $('#cart-vid').trigger('click');
        }
      }

      var checks = [];
      $('input:checked.button-check').each(function (d, u) {
        var pid2 = $(u).attr('config-pid');
        var vid2 = $(u).attr('config-vid');
        checks.push({
          pid: pid2,
          vid: vid2,
        })
      });

      $('#item dl input.image-check').each(function (t, o) {
        $(o).removeClass('inactive');
        var pid = $(o).attr('config-pid');
        var vid = $(o).attr('config-vid');
        for(var i=0;i<configs.length;i++) {
          var c = 0;
          for(var j=0;j<configs[i].vals.length;j++) {
            if(pid === configs[i].vals[j].pid && vid === configs[i].vals[j].vid && parseInt(configs[i].quantity) < 1) {
              for(var k=0;k<configs[i].vals.length;k++) {
                for(var l=0;l<checks.length;l++) {
                  if(checks[l].pid == configs[i].vals[k].pid && checks[l].vid == configs[i].vals[k].vid) {
                    c++;
                  }
                }
              }
            }
          }
          if(configs[i].vals.length-1 === c && checks.length > 0){
            $(o).addClass('inactive');
          }
        }
      });

      if(checks.length === 0) {
        for(var i=0;i<configs.length;i++) {
          $('#item dl input.image-check').each(function (t, o) {
            var pid = $(o).attr('config-pid');
            var vid = $(o).attr('config-vid');
            if(pid === configs[i].vals[0].pid && vid === configs[i].vals[0].vid && parseInt(configs[i].quantity) < 1) {
              $(o).addClass('inactive');  
            }
          });
        }
      }

      $('#item dl input').each(function (i, v) {
        if ($(this).prop('checked')) {
          var configpid = $(this).attr('config-pid');
          var configvid = $(this).attr('config-vid');

          $(this).parent().find('.thumb-image').each(function () {
            $('#cart-thumb').val($(this).attr('src'));
          });
          
          $('#item .item-thumb').each(function () {
            if ($(this).attr('config-pid') == configpid && $(this).attr('config-vid') == configvid) {
              $(this).trigger('click');
              return false;
            }
          });
        }
      });
    }
    else {
      $('#item dl input').each(function (i, v) {
        if ($(this).prop('checked')) {
          var vid = $(this).attr('config-vid');
          var v = $(this).attr('config-v');
          var price = $(this).attr('config-p');
          var discount = $(this).attr('config-d');
          $('#cart-vid').val(vid);
          $('#cart-version').val(v);
          $('#cart-price').val(price);
          $('#cart-discount').val(discount);
          if (parseInt(price.replace(/,/g, '')) > parseInt(discount.replace(/,/g, '')) && parseInt(discount.replace(/,/g, '')) > 0) {
            var t = price;
            price = discount;
            discount = t;
            $('#item-discount-container').show();
            $('#item-discount').html(discount);
            $('#cart-discount').val('1');
          }
          else {
            $('#item-discount-container').hide();
            $('#item-discount').html('');
            $('#cart-discount').val('0');
          }
          $('#item-price').html(price);
        }
      });
    }
  }

  $('#cart-vid').on('click', function () {
    window.history.pushState(null, null, `?skuId=${$(this).val()}`);
  });

  //onchange
  $('#item dl input').on('change', function (e) {
    var configpid = $(this).attr('config-pid');
    var configvid = $(this).attr('config-vid');
    $('#item dl input').each(function (i, v) {
      if ($(this).attr('config-pid') == configpid) {
        if ($(this).attr('config-vid') != configvid) {
          $(this).prop('checked', false);
        } else if ($(this).attr('config-vid') == configvid) {
          $(this).prop('checked', true);
        }
      }
    });
    if($("#item-ot").length > 0) {
      if (typeof $(this).attr('config-d') != 'undefined' && typeof $(this).attr('config-p') != 'undefined') {
        var p = parseInt($(this).attr('config-p').replace(/,/g, ''));
        var d = parseInt($(this).attr('config-d').replace(/,/g, ''));
        $('#cart-version').val($(this).attr('config-v'));
        if(p > d) {
          $('#cart-price').val(d);
          $('#item-price').html($(this).attr('config-d'));
          $('#item-discount-container').show();
          $('#item-discount').html($(this).attr('config-p'));
        }
        else {
          $('#cart-price').val(p);
          $('#item-price').html($(this).attr('config-p'));
          $('#item-discount-container').hide();
          $('#item-discount').html('');
        }
      } else {
        checkItem();
      }
    }
    else {
      checkItem();
    }
  });

  if($("#item").length > 0) {
    initItem();
  }

  //order
  function orderAddress() {
    var address = '';
    if (typeof $('#order-address').attr('value') != 'undefined') {
      address = '&address=' + $('#order-address').attr('value');
    }
    $.ajax({
      type: 'GET',
      url: '/order/address?id=' + $('#order-province').val() + address,
      success: function (data) {
        $('#order-address').html(data);
      },
    });
  }

  $('select#order-province').on('change', function () {
    orderAddress();
  });

  var air = 0;

  $('select#order-shipping').on('change', function () {
    if($(this).val() == 'truck') {
      air = 0;
      $('#order-ground').show();
      $('#order-air').hide();
    } else {
      air = 1;
      $('#order-ground').hide();
      $('#order-air').show();
    }
  });

  if ($('#order').length > 0) {
    orderAddress();
  }

  $('.product-comment-placeholder').on('input', function (e) {
    var version = $(this).data('version');
    var id = $(this).data('id');
    var comment = $(this).val();
    shoppingCart.setComment(id, version, comment);
  });
  
  $('#order-submit').on('click', function(e) {
    if($('#order_name').val().length < 1) {
      $('#order_name').focus();
      return;
    }
    if($('#order_phone').val().length < 8) {
      alert('Та утасны дугаараа бүрэн оруулна уу.');
      $('#order_phone').focus();
      return;
    }
    if($('#order_description').val().length < 1) {
      $('#order_description').focus();
      return;
    }
    if(air == 1) {
      if($('#order_shipping_fname').val().length < 1) {
        $('#order_shipping_fname').focus();
        return;
      }
      if($('#order_shipping_lname').val().length < 1) {
        $('#order_shipping_lname').focus();
        return;
      }
      if($('#order_shipping_reg').val().length < 1) {
        $('#order_shipping_reg').focus();
        return;
      }
    }
    
    $('body').loadingModal({ text: 'Tаны захиалгыг шалгаж байна...' });
    $('#order').submit();
    
    var delay = function (ms) {
        return new Promise(function (r) {
          setTimeout(r, ms);
        });
      };
      var time = 60000;

      delay(time)
        .then(function () {
          $('body').loadingModal('hide');
          return delay(time);
        })
        .then(function () {
          $('body').loadingModal('destroy');
        });
  });

  $(window).on('popstate', function () {
    scrollPosition = [window.scrollX, window.scrollY];
    Turbolinks.visit(window.location.toString(), { action: 'replace' });
  });

  $('#link .btn').on('click', function () {
    let cny = $('input[name="cny"]').val();
    let otid = $('input[name="otid"]').val();
    let vid = $('input[name="vid"]').val();
    let link = $('input[name="link"]').val();
    let shop = $('input[name="shop"]').val();
    let version = $('input[name="version"]').val();
    let name = $('input[name="name"]').val();
    let price = $('input[name="price"]').val();
    let quantity = $('input[name="quantity"]').val();
    let thumb = $('input[name="thumb"]').val();
    let discount = false;
    let validate = true;

    if(link.length > 0) {
      $('input[name="link"]').removeClass('border-danger');
    }
    else {
      $('input[name="link"]').addClass('border-danger');
      validate = false;
    }
    if(version.length > 0) {
      $('input[name="version"]').removeClass('border-danger');
    }
    else {
      $('input[name="version"]').addClass('border-danger');
      validate = false;
    }
    if(name.length > 0) {
      $('input[name="name"]').removeClass('border-danger');
    }
    else {
      $('input[name="name"]').addClass('border-danger');
      validate = false;
    }
    if(price.length > 0) {
      $('input[name="price"]').removeClass('border-danger');
    }
    else {
      $('input[name="price"]').addClass('border-danger');
      validate = false;
    }
    if(quantity.length > 0) {
      $('input[name="quantity"]').removeClass('border-danger');
    }
    else {
      $('input[name="quantity"]').addClass('border-danger');
      validate = false;
    }

    if(validate) {
      price = parseInt(price) * parseInt(cny);
      quantity = parseInt(quantity);

      shoppingCart.add(
        otid,
        vid,
        link,
        shop,
        version,
        name,
        thumb,
        price,
        quantity,
        '',
        discount,
      );

      loadCart();
      window.location = "/cart";
    } else {
      Toastify({
        text: 'Захиалгын мэдээллээ бүрэн оруулна уу.',
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'center',
      }).showToast();
    }
  });

  $('#order-agree').on('change', function () {
    $('#order-submit').prop('disabled', !$(this).prop('checked'));
  });

  if ($('#calc').length > 0) {
    setTimeout(function () {
      var h = $('#container-size').height();
      $('#container-weight').height(h);
    }, 250);
    var price_kg = parseInt($("#cw").val());
    var price_volume = parseInt($("#cs").val());

    $('#calc-weight').keyup(function () {
      if (parseFloat(this.value) <= 1.0) {
        $('#price-weight').val(parseInt(price_kg * 2) + ' ₮');
      } else {
        $('#price-weight').val(
          parseInt((parseFloat(this.value)) * price_kg) + ' ₮'
        );
      }

      if (!this.value) {
        $('#price-weight').val('0 ₮');
      }
    });

    $('#calc-length,#calc-width,#calc-height').keyup(function () {
      $('#price-size').val(
        parseInt(
          (($('#calc-length').val() *
            $('#calc-width').val() *
            $('#calc-height').val()) /
            1000000) *
            price_volume
        ) + ' ₮  '
      );
    });
  }

  $(document).on('click', '.dropdown-menu', function (e) {
    e.stopPropagation();
  });

  $('.top').on('click', function (event) {
    $(window).scrollTop(0);
  });

  $('.cd-dropdown-trigger').on('click', function (event) {
    event.preventDefault();
    toggleNav();
  });

  $('.cd-dropdown .cd-close').on('click', function (event) {
    event.preventDefault();
    toggleNav();
  });

  $('.has-children')
    .children('a')
    .on('click', function (event) {
      event.preventDefault();
      var selected = $(this);
      selected
        .next('ul')
        .removeClass('is-hidden')
        .end()
        .parent('.has-children')
        .parent('ul')
        .addClass('move-out');
    });

  $('.cd-dropdown-content').menuAim({
    activate: function (row) {
      $(row).children().addClass('is-active').removeClass('fade-out');
      if ($('.cd-dropdown-content .fade-in').length == 0)
        $(row).children('ul').addClass('fade-in');
    },
    deactivate: function (row) {
      $(row).children().removeClass('is-active');
      if (
        $('li.has-children:hover').length == 0 ||
        $('li.has-children:hover').is($(row))
      ) {
        $('.cd-dropdown-content').find('.fade-in').removeClass('fade-in');
        $(row).children('ul').addClass('fade-out');
      }
    },
    exitMenu: function () {
      $('.cd-dropdown-content').find('.is-active').removeClass('is-active');
      return true;
    },
    submenuDirection: 'right',
  });

  $('.go-back').on('click', function () {
    var selected = $(this),
      visibleNav = $(this).parent('ul').parent('.has-children').parent('ul');
    selected
      .parent('ul')
      .addClass('is-hidden')
      .parent('.has-children')
      .parent('ul')
      .removeClass('move-out');
  });

  function toggleNav() {
    var navIsVisible = !$('.cd-dropdown').hasClass('dropdown-is-active')
      ? true
      : false;
    if ($('#aside-container').length > 0) {
      if (navIsVisible) {
        $('#aside-container').removeClass('d-lg-block d-xl-block');
      } else {
        $('#aside-container').addClass('d-lg-block d-xl-block');
      }
    }
    $('.cd-dropdown').toggleClass('dropdown-is-active', navIsVisible);
    $('.cd-dropdown-trigger').toggleClass('dropdown-is-active', navIsVisible);
    if (!navIsVisible) {
      $('.cd-dropdown').one(
        'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
        function () {
          $('.has-children ul').addClass('is-hidden');
          $('.move-out').removeClass('move-out');
          $('.is-active').removeClass('is-active');
        }
      );
    }
  }
});
